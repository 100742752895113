import './App.css';
//import React, { useState } from 'react';

import Main from './Main';
import Login from './Login';
import Register from './Register';
import Mon from './Mon';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import useToken from './useToken';

function App() {
  const { token, setToken } = useToken()
  
  
  return (
  
    <div className="App">
	  <BrowserRouter>
        <Routes>
			<Route path="/" element={
			  token?<Main access_token={token}/> : <Login setToken={setToken} />

				} />
			<Route path="/register" element={<Register />} />
			<Route path="/monitoring" element={<Mon />} />
        </Routes>
      </BrowserRouter>
	
	
    </div>
  );
}

export default App;
