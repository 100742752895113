import { useSearchParams } from 'react-router-dom';
import React from 'react'
import MonDevice from './MonDevice';

function Mon() {
  const [searchParams] = useSearchParams()
 
    
  return (

<div className="container loginform">
 <div className="w-100" style={{margin: "auto"}}>
	<MonDevice device={searchParams.get("device")} />
  </div>
</div>
  );
}

export default Mon;
