import React, { useState } from 'react'
import { Link } from 'react-router-dom';

function Register() {
	const [login, setLogin]= useState('')
	const [state, setState]= useState({state: "0", desc: 'Старт регистрации'})
	
	const [emailCode, setEmailCode]= useState('')
	const [name, setName]= useState('')
	
	const [pass, setPass]= useState('')
	const [passc, setPassC]= useState('')
	
	
  function sendCode(event) {
	event.preventDefault()
	let url='https://cloud.slsys.io/api/user/check'
	fetch(url, {
		method: 'POST',
		headers: {
		  'Content-Type': 'application/x-www-form-urlencoded',
		},		
		body: 'email=' +login
	})
	.then(response => response.json())
	.then(data => {
	  let result
	  if(data.success===true) {
		  result={state: '1', desc:data.result}
	  } else {
		  result={state: '100', desc:data.result}
	  }
	  setState(result)
	  //console.log(result)
	})
  }
  function submitReg(event) {
	event.preventDefault()
	let url='https://cloud.slsys.io/api/user/reg'
	fetch(url, {
		method: 'POST',
		headers: {
		  'Content-Type': 'application/x-www-form-urlencoded',
		},		
		body: 'email=' + login + '&email_code=' + emailCode + '&password=' + pass + '&name=' + name
	})
	.then(response => response.json())
	.then(data => {
	  let result
	  if(data.success===true) {
		  result={state: '3', desc:data.result}
	  } else {
		  result={state: '300', desc:data.result}
	  }
	  setState(result)
	  console.log(result)
	})
  }
  return (
  
<div className="loginform">
 <div className="container" style={{maxWidth:"600px",margin: "auto"}}>
  <form onSubmit={sendCode} noValidate >
    <div className="text-center">
    <img className="mb-4" src="/sls.svg" alt="" width="72" />
      <h2 className="mb-3">Регистрация аккаунта</h2>
	</div> 
	{
	state.state==='1' &&
	<div className="alert alert-warning" role="alert">
		<i className="bi bi-exclamation-triangle me-2" style={{fontSize:"20px"}}></i>
		{state.desc}
	</div>
	}
	{
	state.state==='100' &&
	<div className="alert alert-danger" role="alert">
		<i className="bi bi-exclamation-triangle me-2" style={{fontSize:"20px"}}></i>
	    {state.desc}
	</div>
	}

	{
	state.state==='3' && state.desc==='ok' &&
	<div className="alert alert-success" role="alert">
		<i className="bi bi-info-circle me-2" style={{fontSize:"20px"}}></i>
	  Регистрация завершена. Воспользуйтесь <Link to="/">формой входа</Link>.
	</div>
	}	
	{
	state.state==='300' &&
	<div className="alert alert-danger" role="alert">
		<i className="bi bi-exclamation-triangle me-2" style={{fontSize:"20px"}}></i>
	    {state.desc}
	</div>
	}	
            <div className="col-sm-12">
              <label htmlFor="email" className="form-label">Email</label>
              <input type="email" className="form-control" placeholder="email" required value={login} onChange={event => setLogin(event.target.value)} />
              <div className="invalid-feedback">
                Valid first name is required.
              </div>
            </div>   
            <div className="col-sm-12">
              <label htmlFor="firstName" className="form-label">Проверочный код</label>
				<div className="input-group mb-3">
				<button className="btn btn-outline-secondary col-md-6" type="submit">Получить код</button>
				  <input type="text" className="form-control" placeholder="Код из письма" aria-label="Код из письма" aria-describedby="button-addon2" value={emailCode} onChange={event => setEmailCode(event.target.value)}/>
				  
				</div>
              <div className="invalid-feedback">
                Valid first name is required.
              </div>
            </div> 
			
	</form>
	<form onSubmit={submitReg}>
            <div className="col-sm-12">
              <label htmlFor="firstName" className="form-label">Имя</label>
              <input type="text" className="form-control" placeholder="Имя или никнэйм" value={name} onChange={event => setName(event.target.value)} required />
              <div className="invalid-feedback">
                Valid first name is required.
              </div>
            </div> 			
            <div className="col-sm-12">
              <label htmlFor="firstName" className="form-label">Пароль</label>
              <input type="password" className="form-control"  required  value={pass} onChange={event => setPass(event.target.value)}/>
              <div className="invalid-feedback">
                Valid first name is required.
              </div>
            </div>
			<div className="col-sm-12 mb-3">
              <label htmlFor="firstName" className="form-label">Повтор пароля</label>
              <input type="password" className="form-control" required value={passc} onChange={event => setPassC(event.target.value)}/>
              <div className="invalid-feedback">
                Valid first name is required.
              </div>
            </div>  		
	  <div className="d-grid gap-2 d-sm-flex justify-content-sm-center mb-5">
	  {state.desc!=='ok' && <button type="submit" className="btn btn-primary btn-lg px-4 gap-3">Зарегистрироваться</button>}
      </div>
  </form>
 </div>
</div>

  );
}

export default Register;
