//import { useSearchParams } from 'react-router-dom';
import React, { useEffect } from 'react'
//import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Gw from './MonDevice';


function Mon(params) {
	const [userDevices, setUserDevices] = React.useState([])
	
 useEffect(() => {
	    let url='https://cloud.slsys.io/api/device/list?access_token=' + params.access_token
		fetch(url)
		  .then(response => response.json())
		  .then(data => {
			  if(data.success===true) {
				setUserDevices(data.result);
			 } else {
				 data.result=false;
				 setUserDevices(data.result);
			 }
		  }
	  )	  
  }, [params.access_token])
 
 
  function renderGateways() {
	  return userDevices.map( gw => {
		return (
			<div className="col-xxl-3 col-xl-4 col-lg-6 col-md-6 col-sm-12" key={gw.token}>
			  <Gw 
				device={gw.token}
				device_name={gw.name}
				hide_add_btn={true}
			  />
			</div>
		)
	  })	  	  
  }


 
  return (



<main role="main" className="container">
<h3 className="mb-3 text-center">Добро пожаловать в личный кабинет</h3>

<ul className="nav nav-pills nav-fill">
  <li className="nav-item">
    <a className="nav-link active" aria-current="page" href="/lk/monitoring">Мониторинг шлюзов</a>
  </li>
  <li className="nav-item">
    <a className="nav-link" href="/lk/control">Управление устройствами</a>
  </li>
  <li className="nav-item">
    <a className="nav-link" href="/lk/profile">Профиль</a>
  </li>
</ul><br/>
	<div className="row">
	{ renderGateways() }
	</div>

</main>
  );
}

export default Mon;
