import './Login.css';
import React, { useState } from 'react'
import { Link } from 'react-router-dom';


function Login({ setToken }) {
	
	
  const [login, setLogin]= useState('')
  const [pass, setPass]= useState('')

  const [state, setState]= useState({state: "0", desc: 'Вход'}) 
  
  function submitLogin(event) {
	event.preventDefault()
	let url='https://cloud.slsys.io/api/user/auth'
	fetch(url, {
		method: 'POST',
		headers: {
		  'Content-Type': 'application/x-www-form-urlencoded',
		},		
		body: 'email=' +login+ '&password='+ pass+'&application=lk_app'
	})
	.then(response => response.json())
	.then(data => {
	  let result
	  if(data.success===true) {
		  setToken(data)
		  result={state: '1', desc:data.result}
	  } else {
		  result={state: '100', desc:data.result}
	  }		  
	  setState(result)
	  
	})
  }
  
  return (
  
<div className="loginform">
<div className="form-signin text-center">
  <form onSubmit={submitLogin}>
    <img className="mb-4" src="/sls.svg" alt="" width="72" />
    <h3 className="h3 mb-3 fw-normal">Личный кабинет</h3>
	<div className="mb-3">Войдите или <Link to="/register">зарегистрируйтесь</Link>.</div>
	{
	state.state==='1' &&
	<div className="alert alert-warning text-start" role="alert">
	    <i className="bi bi-exclamation-triangle me-2" style={{fontSize:"20px"}}></i>
		{state.desc}
	</div>
	}
	{
	state.state==='100' &&
	<div className="alert alert-danger text-start" role="alert" >
	  <i className="bi bi-exclamation-triangle me-2" style={{fontSize:"20px"}}></i>
	  {state.desc}
	</div>
	}
	
    <div className="form-floating">
      <input type="email" id="floatingInput" className="form-control" placeholder="Почта" value={login} onChange={event => setLogin(event.target.value)} />
      <label htmlFor="floatingInput">Почта</label>
    </div>
    <div className="form-floating">
      <input type="password" id="floatingPassword" className="form-control"  placeholder="Пароль" value={pass} onChange={event => setPass(event.target.value)}/>
      <label htmlFor="floatingPassword">Пароль</label>
    </div>
	
    <button className="w-100 btn btn-lg btn-primary" type="submit">Войти</button>
    <p className="mt-5 mb-3 text-muted">&copy; SLS 2019–2022</p>
  </form>
  
</div>  
 
</div>



  );
}

export default Login;
