import React, { useEffect, useState } from 'react'
import useToken from './useToken';
import { useNavigate } from "react-router-dom";

function MonDevice(params) {
	const [devProps, setDevProps] = React.useState([])
	const [loaded, setLoaded] = React.useState(false)
	const [name, setName] = useState(params.device_name)
	const [editing, setEditing] = useState(false)
	const { token } = useToken()	
	const navigate = useNavigate();
	
  useEffect(() => {
	  const interval=setInterval(() => {
	    let url='https://cloud.slsys.io/api/device/' + params.device
		fetch(url)
		  .then(response => response.json())
		  .then(data => {
			  if(data.success===true) {	
				data.result.success=true;
				data.result.uptime_sec=secToTime(data.result.uptime_sec);
				setDevProps(data.result)
				setLoaded(true)
			 } else {
				 data.result=false;
				 setDevProps(data.result);				 
			 }
			 console.log(data)
		  }
	  )}, 20000);
	    let url='https://cloud.slsys.io/api/device/' + params.device
		fetch(url)
		  .then(response => response.json())
		  .then(data => {
			  if(data.success===true) {
				data.result.uptime_sec=secToTime(data.result.uptime_sec);
				setDevProps(data.result)
				setLoaded(true)
			 } else {
				 data.result=false;
				 setDevProps(data.result);
			 }
		  }
	  )	  
	  return () => clearInterval(interval);
  }, [params.device])
  
  
  
 /* function tsToDate(timestamp) {
	let date = new Date(timestamp * 1000);
	let hours = date.getHours();
	let minutes = "0" + date.getMinutes();
	let seconds = "0" + date.getSeconds();
	let month = "0" + date.getDate();
	let day = "0" + (date.getMonth()+1);
	let formattedTime = day.substr(-2) + '.' + month.substr(-2) + '.' + date.getFullYear() + ' ' +hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);	
	return formattedTime;
  }*/
  
  function secToTime(seconsds) {
	let hours = Math.floor(seconsds/(60*60));
	let minutes = '0' + Math.floor((seconsds-hours*60*60)/(60));
	let seconds = '0' + (seconsds-(hours*60*60)-(minutes*60));
	let formattedTime = hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);
	return formattedTime;	
  }
  function addToToken() {
	let url='https://cloud.slsys.io/api/device/link'
	fetch(url, {
		method: 'POST',
		headers: {
		  'Content-Type': 'application/x-www-form-urlencoded',
		},		
		body: 'token=' +params.device+ '&access_token='+ token
	})
	.then(response => response.json())
	.then(data => {
	  //let result
	  if(data.success===true) {
		  //result={state: '1', desc:data.result}
		  navigate("/");	
	  } else {
		  //result={state: '100', desc:data.result}
	  }
		  
	})
  }
  
  function unlinkFromToken() {
	let url='https://cloud.slsys.io/api/device/unlink'
	fetch(url, {
		method: 'POST',
		headers: {
		  'Content-Type': 'application/x-www-form-urlencoded',
		},		
		body: 'token=' +params.device+ '&access_token='+ token
	})
	.then(response => response.json())
	.then(data => {
	  //let result
	  if(data.success===true) {
		  //result={state: '1', desc:data.result}
	  } else {
		  //result={state: '100', desc:data.result}
	  }		
	})
  }
  
  function renameDevice() {
	  setEditing(!editing)
  }
  
  function submitRename() {
	let url='https://cloud.slsys.io/api/device/link'
	fetch(url, {
		method: 'POST',
		headers: {
		  'Content-Type': 'application/x-www-form-urlencoded',
		},		
		body: 'token=' +params.device+ '&access_token='+ token + '&name='+ name
	})
	.then(response => response.json())
	.then(data => {
	  //let result
	  if(data.success===true) {
		  //result={state: '1', desc:data.result}
		  navigate("/");	
	  } else {
		  //result={state: '100', desc:data.result}
	  }
		  
	})	  
  }
  
  return (

<div className="card h-100">
<div className="card-header d-flex justify-content-between align-items-center" id="device_id">
		 
			 
		{ params.device && loaded ?
		
		
			editing ? 
			   <form className="col-12" style={{"marginTop":"-3.5px", "marginBottom":"-3.5px"}} onSubmit={submitRename}>
				<div className="input-group input-group-sm">
				  <input type="text" className="form-control border-secondary" placeholder="Имя шлюза" aria-label="Имя шлюза" aria-describedby="button-addon2" value={name} onChange={event => setName(event.target.value)}/>
				  <button className={"btn btn-outline-success border-secondary"} id="button-addon2" type='submit'><i className="bi bi-check-lg" aria-hidden="true"></i></button>
				  <button className={"btn btn-outline-danger border-secondary"} id="button-addon2" type='button' onClick={renameDevice}><i className="bi bi-x" aria-hidden="true"></i></button>
				</div>
			  </form>
			 : 
				params.device_name ? params.device_name : params.device.substr(params.device.length - 4 )
		
		: "Устройство не найдено"} 
		{ token && loaded && !params.hide_add_btn ?
		<button type="button" className="btn btn-sm btn-success float-end" onClick={addToToken}><i className="bi bi-link-45deg"></i> Привязать</button>
		:""}
		{ !editing && token && loaded && params.hide_add_btn ?
		
		<div className="dropdown float-end">
		<div className="text-dark" role="button" data-bs-toggle="dropdown" aria-expanded="false"><i className="bi bi-three-dots-vertical"></i></div>
		  <ul className="dropdown-menu dropdown-menu-end">
		  
			<li role="button"><div className="dropdown-item" onClick={renameDevice}><i className="bi bi-pencil-square"></i> Переименовать</div></li>
			<li role="button"><div className="dropdown-item" onClick={unlinkFromToken}><i className="bi bi-person-x-fill"></i> Отвязать</div></li>
			
		  </ul>
		</div>
		:""}
		
		
</div>

	<div className="card-body">
		<div className="row">
		  <div className="col-sm">
			  <b>Param</b>
		   </div>
		  <div className="col-sm">
			  <b>Value</b>
		   </div>
		</div>
	
        <div className="row">
          <div className="col-sm">mac
           </div>
          <div className="col-sm">{ devProps.mac }</div>             
          </div>
        
        
        <div className="row">
          <div className="col-sm">fw_ver
           </div>
          <div className="col-sm">{ devProps.fw_ver }
           </div>             
          </div>
        
        
        <div className="row">
          <div className="col-sm">uptime_sec
           </div>
          <div className="col-sm">{ devProps.uptime_sec }
           </div>             
          </div>
        
        
        <div className="row">
          <div className="col-sm">freemem
           </div>
          <div className="col-sm">{ devProps.freemem }
           </div>             
          </div>
        
        </div>		
</div>
  );
}

export default MonDevice;
